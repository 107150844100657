import { useTheme } from "@mui/material";

import "./Short.css";
import { images } from "@assets";

type ShortProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
};

export const Short = (props: ShortProps) => {
  const theme = useTheme();

  const className = props.className
    ? `BCTLogoShort ${props.className}`
    : "BCTLogoShort";
  const size = `${props.size || 72}px`;
  const style: React.CSSProperties = {
    height: size,
    ...props.style,
  };

  return (
    <div className={className} style={style}>
      <img
        src={images.bitcoinPng}
        alt="Bitcoin Connecticut"
        className="BCTLogoShort-image"
        style={{
          width: size,
          height: size,
        }}
      />
      <p
        className="BCTLogoShort-text"
        style={{
          color: theme.palette.secondary.contrastText,
          fontSize: size,
          lineHeight: size,
        }}
      >
        CT
      </p>
    </div>
  );
};
