import { useState, useEffect } from "react";

import * as Types from "types";
import { UIContext } from "./UIContext";

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [colorTheme, setColorTheme] = useState<Types.UI["colorTheme"]>("light");
  const [layout, setLayout] = useState<Types.UI["layout"]>("mobile");

  const toggleLayout = () => {
    setLayout(layout === "mobile" ? "desktop" : "mobile");
  };

  const toggleColorTheme = () => {
    setColorTheme(colorTheme === "light" ? "dark" : "light");
  };

  return (
    <UIContext.Provider
      value={{
        colorTheme,
        toggleColorTheme,
        setColorTheme,

        layout,
        toggleLayout,
        setLayout,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
