import "./index.css";

type PageProps = {
  /** Class name */
  className?: string;

  /** CSS overrides */
  style?: React.CSSProperties;

  /** Content */
  children: React.ReactNode;
};

/** Default page layout. */
export const Page = (props: PageProps) => {
  const className = props.className ? `Page ${props.className}` : "Page";

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
};
