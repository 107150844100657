import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as Pages from "@pages";
import { Header, HeadNav, Footer } from "@components";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <HeadNav />

      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/resources" element={<Pages.Resources />} />
        <Route path="/join-us" element={<Pages.JoinUs />} />
        <Route path="*" element={<Pages.Wildcard />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
};
