import { useTheme } from "@mui/material";

import "./index.css";

type TileProps = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export const Tile = (props: TileProps) => {
  const theme = useTheme();

  const style: React.CSSProperties = {
    ...props.style,
  };
  const className = props.className ? `Tile ${props.className}` : "Tile";

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};
