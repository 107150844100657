import { useTheme } from "@mui/material";

import "./index.css";
import { IconLinkRow } from "@components";

export const Footer = () => {
  const theme = useTheme();

  return (
    <div
      className="Footer"
      style={{
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <div className="Footer-content">
        <div className="Footer-links">
          <IconLinkRow />
        </div>

        <div
          className="Footer-copyright"
          style={{
            color: theme.palette.secondary.contrastText,
          }}
        >
          © Bitcoin Connecticut
        </div>
      </div>
    </div>
  );
};
