import "./index.css";
import { images } from "@assets";

type MeetupIconProps = {};

export const MeetupIcon = (props: MeetupIconProps) => {
  return (
    <div className="MeetupIcon">
      <a href="https://meetup.com/bitcoin-connecticut">
        <img
          className="MeetupIcon-image"
          src={images.meetupIcon}
          alt="Meetup"
        />
      </a>
    </div>
  );
};
