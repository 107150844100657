import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import "./index.css";
import { BCTLogo } from "@components";
import { HeadNavDesktop } from "./HeadNavDesktop";
import { HeadNavMobile } from "./HeadNavMobile";

export const HeadNav = () => {
  const location = useLocation();
  const theme = useTheme();

  /** Hidden state (default to true on homepage) */
  const [hidden, setHidden] = useState(false);

  const style: React.CSSProperties = {
    background: theme.palette.secondary.main,
  };

  useEffect(() => {
    // Only hide/show on homepage
    if (location.pathname !== "/") return;

    /** Set active state based on scroll position */
    const checkScroll = () => {
      const show = window.scrollY >= 200;

      //TODO: There has to be a better way to do this
      /*       if (show) setHidden(false);
      else setHidden(true); */
    };

    window.addEventListener("scroll", checkScroll);

    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <div className="HeadNav" data-hidden={hidden} style={style}>
      <div className="HeadNav-content">
        <Link to="/" className="HeadNav-logo">
          <BCTLogo.Short size={32} />
        </Link>

        <div className="HeadNav-nav">
          <HeadNavDesktop />
          <HeadNavMobile />
        </div>
      </div>
    </div>
  );
};
