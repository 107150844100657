type PageInnerContentProps = {
  className?: string;
  style?: React.CSSProperties;

  children: React.ReactNode;
};

const defaultStyles: React.CSSProperties = {
  margin: "0 auto",
  width: "90%",
  maxWidth: "1300px",
};

export const PageInnerContent = (props: PageInnerContentProps) => {
  const className = props.className
    ? `PageInnerContent ${props.className}`
    : "PageInnerContent";
  const style: React.CSSProperties = {
    ...defaultStyles,
    ...props.style,
  };

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};
