import { useTheme } from "@mui/material";

import "./Long.css";
import { images } from "@assets";

type LongProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
};

export const Long = (props: LongProps) => {
  const theme = useTheme();

  return (
    <div className="BCTLogoLong">
      <img
        src={images.bitcoinTextPng}
        alt="Bitcoin Connecticut"
        className="BCTLogoLong-image"
      />

      <p
        className="BCTLogoLong-text"
        style={{ color: theme.palette.primary.main }}
      >
        Connecticut
      </p>
    </div>
  );
};
