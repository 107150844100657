import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";

import "./index.css";

export const HeadNavDesktop = () => {
  const theme = useTheme();
  return (
    <div className="HeadNavDesktop">
      <div className="HeadNavDesktop-links">
        <HeadNavLink to="/resources">Resources</HeadNavLink>

        <HeadNavLink to="/join-us">Join Us</HeadNavLink>
      </div>
    </div>
  );
};

type HeadNavLinkProps = {
  to: string;
  children: React.ReactNode;
};

const HeadNavLink = (props: HeadNavLinkProps) => {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const defaultStyles: React.CSSProperties = {
    color: theme.palette.secondary.contrastText,
  };
  const hoverStyles: React.CSSProperties = {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  return (
    <Link
      className="HeadNavDesktop-link"
      to={props.to}
      style={{ ...defaultStyles, ...(hover ? hoverStyles : {}) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-hover={hover}
    >
      <div>{props.children}</div>
    </Link>
  );
};
