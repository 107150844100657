import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Helmet from "react-helmet";

import "./index.css";
import { images } from "@assets";
import { Page, PageTitle, PageInnerContent, Tile, Padding } from "@components";

export const Resources = () => {
  return (
    <Page className="Resources">
      <Helmet title="Resources | BitcoinCT" />

      <PageTitle
        title="Resources"
        subtitle="Your guide to Bitcoin"
        style={{ marginBottom: "12px" }}
      />

      {/*       <div className="Resources-page-background">
        <img src={images.croppedNodeBackground1} />
      </div> */}

      <PageInnerContent>
        <div className="Resources-tiles">
          <Tile className="Resources-tile">
            <h2 id="what-is-bitcoin">What is Bitcoin?</h2>
            <p>
              If you're totally new to Bitcoin, we hope these resources can help
              you along.
            </p>

            <List>
              <ListItem>
                <ListItemText>
                  <a href="https://bitcoin.org/bitcoin.pdf">The Whitepaper</a>:
                  This is the paper that started it all; the fundamental mission
                  statement of Bitcoin.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <a href="https://bitcoin.org">Bitcoin(.org)</a>: The closest
                  thing bitcoin has to an 'official website'.
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <a href="https://thebitcoinmanual.com">The Bitcoin Manual</a>:
                  A great resource for beginners, to understand the culture and
                  the currency.
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <a href="https://thebitcoinmanual.com/btc-culture/glossary">
                    Bitcoin Glossary
                  </a>
                  : A glossary of terms used in the Bitcoin community. If you
                  keep hearing a word you don't understand, just type it in the
                  search bar.
                </ListItemText>
              </ListItem>
            </List>
          </Tile>

          <Tile className="Resources-tile">
            <h2 className="Resources-tile-title" id="beginner-podcasts">
              Beginner Podcasts
            </h2>
            <p>
              Learn the basics of bitcoin during your commute to work (or our
              meetups)
            </p>

            <List>
              <ListItem>
                <ListItemText>
                  <a href="https://www.bluecollarbitcoin.io/bitcoinbasics">
                    Bitcoin Basics Series
                  </a>
                  : A podcast series by <i>Blue Collar Bitcoin</i>, made for
                  absolute beginners. (Series also available on YouTube)
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <a href="https://open.spotify.com/playlist/2qUVXwvOWNS3rhD8JFWoEK?si=_Zn-haFAR7iKrwV0QXQw7g&nd=1">
                    Basics
                  </a>
                  : A spotify series by <i>Bitcoin Audible</i>, each episode
                  tackles one basic component of bitcoin.
                </ListItemText>
              </ListItem>
            </List>
          </Tile>

          <Tile className="Resources-tile">
            <h2 className="Resources-tile-title">Beginner Videos</h2>
            <p>
              Sit back, relax and learn everything you need to know about how
              Bitcoin works.
            </p>

            <List>
              <ListItem>
                <ListItemText>
                  <a href="https://www.youtube.com/watch?v=bBC-nXj3Ng4">
                    But how does bitcoin actually work?
                  </a>
                  : A great video by 3Blue1Brown, explaining the basics of how
                  the bitcoin protocol works.
                </ListItemText>
              </ListItem>
            </List>
          </Tile>
        </div>
      </PageInnerContent>
      <div className="Resources-padding"></div>
    </Page>
  );
};
