type PaddingProps = {
  /** CSS overrides */
  style?: React.CSSProperties;

  children: React.ReactNode;
};

const defaultStyles: React.CSSProperties = {
  padding: "8px",
};

export const Padding = (props: PaddingProps) => {
  const style = {
    ...defaultStyles,
    ...props.style,
  };

  return (
    <div className="Padding" style={style}>
      {props.children}
    </div>
  );
};
