import { useTheme } from "@mui/material";

import "@fontsource/ubuntu";
import "./index.css";

type PageTitleProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;

  /** Main title text */
  title?: string | React.ReactNode;

  /** Subtitle string */
  subtitle?: string | React.ReactNode;
};

/** Main title section for each page */
export const PageTitle = (props: PageTitleProps) => {
  const theme = useTheme();

  const className = props.className
    ? `PageTitle ${props.className}`
    : "PageTitle";
  const style: React.CSSProperties = {
    backgroundColor: theme.palette.secondary.main,
    ...props.style,
  };

  return (
    <div className={className} style={style}>
      {props.title && (
        <h1
          className="PageTitle-title"
          style={{ color: theme.palette.secondary.contrastText }}
        >
          {props.title}
        </h1>
      )}
      {props.subtitle && (
        <p
          className="PageTitle-subtitle"
          style={{ color: theme.palette.secondary.contrastText }}
        >
          {props.subtitle}
        </p>
      )}
      {props.children}
    </div>
  );
};
