import { AppBar } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import "./index.css";
import { images } from "@assets";
import { BCTLogo, IconLinkRow } from "@components";

export const Header = () => {
  const theme = useTheme();

  return (
    <div
      className="Header"
      style={{
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1400,
      }}
    >
      <div className="Header-content">
        <div className="Header-home-icon">
          <BCTLogo.Long />
        </div>

        <div className="Header-external-links">
          <IconLinkRow />
        </div>
      </div>
    </div>
  );
};
