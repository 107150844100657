import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import "@fontsource/ubuntu";
import { Link } from "react-router-dom";

import Helmet from "react-helmet";

import "./index.css";
import {
  Page,
  PageTitle,
  PageInnerContent,
  Padding,
  Header,
} from "@components";

export const Home = () => {
  const theme = useTheme();

  return (
    <Page className="Home">
      <Helmet title="BitcoinCT" />
      <Header />
      <PageTitle
        className="Home-page-title"
        style={{
          backgroundColor: theme.palette.secondary.main,
          paddingTop: "1px",
        }}
        title={
          <>
            Welcome to{" "}
            <span style={{ color: theme.palette.primary.main }}>Bitcoin</span>{" "}
            Connecticut
          </>
        }
        subtitle="Your local gateway to understanding and utilizing Bitcoin."
      />

      <PageInnerContent>
        <Padding>
          <div className="Home-calls-to-action">
            <Link to="/join-us" className="Home-cta-link">
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
              >
                <p className="Home-cta-text">Join Us</p>
              </Button>
            </Link>

            <Link to="/resources" className="Home-cta-link">
              <Button color="primary" size="large" variant="outlined">
                <p className="Home-cta-text">Learn</p>
              </Button>
            </Link>
          </div>
        </Padding>
      </PageInnerContent>

      <PageInnerContent>
        <Padding>
          <div id="about-us">
            <h2 className="Home-subheader">About us</h2>

            <p className="Home-text">
              Bitcoin Connecticut is a diverse community of bitcoin enthusiasts,
              learners, and advocates. Our goal is to provide a platform for
              Connecticut residents to explore Bitcoin, engage in productive
              discussions, and share knowledge about this transformative
              technology. Whether you're a core developer, or you just want to
              know what this 'bitcoin' thing is that you keep hearing about,
              we're here to help!
            </p>
          </div>
        </Padding>
      </PageInnerContent>

      <PageInnerContent>
        <Padding>
          <div id="why-bitcoin">
            <h2 className="Home-subheader">Why Bitcoin?</h2>

            <p className="Home-text">
              The world is changing rapidly, and so too is the concept of money.
              Traditional fiat currencies like the US dollar are not immune to
              problems such as inflation, which can erode your savings over
              time. Bitcoin, a decentralized digital currency, provides an
              alternative that is not controlled by any government or entity. It
              offers potential benefits like:
            </p>

            <List>
              <ListItem className="Home-list-item">
                <ListItemIcon className="Home-list-icon">
                  <AddCircle color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <b>Control:</b> You have complete control over your digital
                  assets.
                </ListItemText>
              </ListItem>

              <ListItem className="Home-list-item">
                <ListItemIcon className="Home-list-icon">
                  <AddCircle color="primary" />
                </ListItemIcon>

                <ListItemText>
                  <b>Transparency:</b> Blockchain technology ensures
                  transparency and prevents fraud, or 'double spending'.
                </ListItemText>
              </ListItem>

              <ListItem className="Home-list-item">
                <ListItemIcon className="Home-list-icon">
                  <AddCircle color="primary" />
                </ListItemIcon>

                <ListItemText>
                  <b>Security:</b> All bitcoin transactions are secured by
                  unbreakable cryptographic technology.
                </ListItemText>
              </ListItem>

              <ListItem className="Home-list-item">
                <ListItemIcon className="Home-list-icon">
                  <AddCircle color="primary" />
                </ListItemIcon>

                <ListItemText>
                  <b>Accessibility:</b> You can send and receive Bitcoin 'sats'
                  anywhere, anytime. Since there is no central entity to ask
                  permission from, there is no entity that can deny you
                  permission.
                </ListItemText>
              </ListItem>

              <ListItem className="Home-list-item">
                <ListItemIcon className="Home-list-icon">
                  <AddCircle color="primary" />
                </ListItemIcon>

                <ListItemText>
                  <b>Potential Growth:</b> While there are risks associated with
                  any investment, we envision a future where Bitcoin is the
                  global reserve currency. US dollar is backed by faith in the
                  government's competence, but Bitcoin is backed by math itself.
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Padding>
      </PageInnerContent>

      <PageInnerContent>
        <Padding>
          <div id="our-goals">
            <h2 className="Home-subheader">Our Goals</h2>

            <List>
              <ListItem>
                <ListItemText>
                  <b>Education:</b> Bitcoin can seem incredibly complex, but
                  we're here to make it accessible and understandable. Whether
                  you're a beginner or a veteran maxi, there's always more to
                  learn about bitcoin and it's potential.
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <b>Community Building:</b> We strive to build a robust,
                  supportive community where members can share their experience
                  and knowledge, ask questions, and collaborate on
                  Bitcoin-related projects.
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <b>Advocacy:</b> We aim to promote Bitcoin to a wider
                  audience, highlighting it's benefits and addressing it's
                  challenges in the context of today's socio-economic landscape.
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Padding>
      </PageInnerContent>

      <div style={{ backgroundColor: theme.palette.primary.main }}>
        <PageInnerContent>
          <Padding>
            <div id="join-us">
              <h2>Join Us</h2>

              <p>
                Whether you're a Bitcoin expert, curious newcomer, or someone
                concerned about the future of the dollar, we welcome you.
                Together, we can explore the possibilities that Bitcoin presents
                and help build a better world for tomorrow.
              </p>
            </div>
          </Padding>
        </PageInnerContent>
      </div>
    </Page>
  );
};
