import { UIProvider } from "./UI";

/** Wrapper component for all global state providers */
export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="StateProvider">
      <UIProvider>{children}</UIProvider>
    </div>
  );
};
