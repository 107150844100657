import { Telegram } from "@mui/icons-material";

type TelegramIconProps = {
  color?: React.CSSProperties["color"];

  size?: "small" | "medium" | "large";

  style?: React.CSSProperties;
};

export const TelegramIcon = (props: TelegramIconProps) => {
  const style: React.CSSProperties = {
    textDecoration: "none",
    color: props.color || "#0088cc",
    ...props.style,
  };
  const size = props.size || "large";

  return (
    <div className="Telegram">
      <a href="https://t.me/+FPy_1jKYfYpjNTQ5" style={style}>
        <Telegram fontSize={size} />
      </a>
    </div>
  );
};
