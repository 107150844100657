import React from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";

import "@fontsource/ubuntu";

import "./App.css";
import { AppRouter } from "@router";
import { StateProvider } from "@state";

function App() {
  const theme = createTheme({
    palette: {
      text: {
        primary: "#ffffff",
      },
      primary: {
        main: "#f7931a",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#13161f",
      },
    },
  });

  return (
    <div className="App">
      <StateProvider>
        <CssBaseline enableColorScheme />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </StateProvider>
    </div>
  );
}

export default App;
