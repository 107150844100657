import { createContext } from "react";
import * as Types from "types";

interface UIContextState {
  /** Layout (mobile or desktop, depending on device/user choice) */
  layout: Types.UI["layout"];

  /** Manually set layout */
  setLayout: (layout: Types.UI["layout"]) => void;

  /** Toggle layout */
  toggleLayout: () => void;

  /** Color theme (light or dark, depending on user choice) */
  colorTheme: Types.UI["colorTheme"];

  /** Manually set color theme */
  setColorTheme: (colorTheme: Types.UI["colorTheme"]) => void;

  /** Toggle color theme */
  toggleColorTheme: () => void;
}

export const UIContext = createContext<UIContextState>({
  layout: "mobile",
  setLayout: () => {},
  toggleLayout: () => {},

  colorTheme: "light",
  setColorTheme: () => {},
  toggleColorTheme: () => {},
});
