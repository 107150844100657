import Helmet from "react-helmet";
import { useTheme } from "@mui/material";
import { useState } from "react";

import "./index.css";
import { socialLinks } from "@data";
import { Page, PageTitle, PageInnerContent, IconLink, Card } from "@components";

export const JoinUs = () => {
  const theme = useTheme();

  const defaultButtonStyles: React.CSSProperties = {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  };

  const hoverButtonStyles: React.CSSProperties = {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.primary.main,
  };

  const [telegramStyle, setTelegramStyle] = useState(defaultButtonStyles);
  const [meetupStyle, setMeetupStyle] = useState(defaultButtonStyles);

  return (
    <Page className="JoinUs">
      <Helmet title="Join us | BitcoinCT" />
      <PageTitle
        title="Join us!"
        subtitle="We'd love to hear from you, in person or online!"
      />

      <PageInnerContent className="JoinUs-content-wrapper">
        <div className="JoinUs-content-padding"></div>
        <div className="JoinUs-content">
          <a
            className="JoinUs-link"
            href={socialLinks.telegram.href}
            style={{ color: theme.palette.secondary.contrastText }}
          >
            <Card
              className="JoinUs-item"
              style={telegramStyle}
              onMouseEnter={() => setTelegramStyle(hoverButtonStyles)}
              onMouseLeave={() => setTelegramStyle(defaultButtonStyles)}
            >
              <div className="JoinUs-item-content">
                <h2>Telegram</h2>
                <p>Our group chat</p>
                <IconLink.Telegram />
              </div>
            </Card>
          </a>

          <a
            className="JoinUs-link"
            href={socialLinks.meetup.href}
            style={{ color: theme.palette.secondary.contrastText }}
          >
            <Card
              className="JoinUs-item"
              style={meetupStyle}
              onMouseEnter={() => setMeetupStyle(hoverButtonStyles)}
              onMouseLeave={() => setMeetupStyle(defaultButtonStyles)}
            >
              <div className="JoinUs-item-content">
                <h2>MeetUp</h2>
                <p>Our in-person meetups</p>
                <IconLink.Meetup />
              </div>
            </Card>
          </a>
        </div>

        <div className="JoinUs-content-padding"></div>
      </PageInnerContent>
    </Page>
  );
};
