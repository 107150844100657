import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import "./index.css";
import { images } from "@assets";
import { Page, PageTitle, Header, PageInnerContent } from "@components";

export const Wildcard = () => {
  const theme = useTheme();

  return (
    <Page className="Wildcard">
      <Header />

      <PageInnerContent className="Wildcard-content-wrapper">
        <div className="Wildcard-content">
          <Link to="/" className="Wildcard-link">
            <h2 className="Wildcard-title">404</h2>
            <img className="Wildcard-logo" src={images.bitcoinPng} alt="" />
            <p>Click the bitcoin to return home</p>
          </Link>
        </div>
      </PageInnerContent>
    </Page>
  );
};
