import { useTheme } from "@mui/material";

import "./index.css";

type CardProps = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const Card = (props: CardProps) => {
  const theme = useTheme();

  const className = props.className ? `Card ${props.className}` : "Card";
  const style: React.CSSProperties = {
    backgroundColor: theme.palette.secondary.dark,
    ...props.style,
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onMouseEnter && props.onMouseEnter();
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onMouseLeave && props.onMouseLeave();
  };

  return (
    <div
      className={className}
      style={style}
      onMouseEnter={(e) => onMouseEnter(e)}
      onMouseLeave={(e) => onMouseLeave(e)}
    >
      {props.children}
    </div>
  );
};
