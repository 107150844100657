import { useTheme, IconButton, Drawer } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import Hamburger from "hamburger-react";

import "./index.css";

export const HeadNavMobile = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  return (
    <div className="HeadNavMobile">
      <div
        className="HeadNavMobile-nav-button"
        style={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Hamburger
          toggled={open}
          toggle={setOpen}
          color={theme.palette.secondary.contrastText}
        />
      </div>

      {open && (
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          className="HeadNavMobile-drawer"
          style={{ zIndex: 0 }}
        >
          <div
            className="HeadNavMobile-drawer-content"
            style={{ backgroundColor: theme.palette.secondary.dark }}
          >
            <div className="HeadNavMobile-drawer-links">
              <DrawerLink href="/">Home</DrawerLink>

              <DrawerLink href="/resources">Resources</DrawerLink>

              <DrawerLink href="/join-us">Join Us</DrawerLink>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

const DrawerLink = (props: { href: string; children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <a
      href={props.href}
      className="HeadNavMobile-drawer-link"
      style={{
        color: theme.palette.secondary.contrastText,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      }}
    >
      {props.children}
    </a>
  );
};
